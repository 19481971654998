.alert {
  position: fixed;
  width: 300px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 10px;
  gap: 20px;
  border-radius: 10px;
  border-top: 2px solid var(--accent-color);
  background-color: var(--bg-color2);
}
.alert__title {
  font-size: 18px;
}
.alert__buttons {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
}
.alert__button {
  cursor: pointer;
  background-color: var(--bg-color);
  padding: 10px 20px;
  border-radius: 4px;
  border: 2px solid var(--accent-color);
}
