.todo-inputs {
  position: relative;
  margin: 30px auto;
  display: flex;
  gap: 10px;
}
.todo-inputs__inputs {
  position: relative;
  display: flex;
  flex-direction: column;
  width: clamp(13.438rem, 6.835rem + 28.17vw, 32.188rem);
  gap: 10px;
}
.todo-inputs__input {
  background-color: var(--bg-color2);
  border: 2px solid var(--accent-color);
  border-radius: 4px;
  outline: none;
  padding: 10px 15px;
  color: #f0e3ca;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}
.todo-inputs__add-button {
  width: 85px;
  background-color: transparent;
  border: 2px solid var(--accent-color);
  border-radius: 4px;
  cursor: pointer;
}
.todo-empty {
  width: 200px;
  height: 100px;
  margin: 150px auto 0 auto;
  align-items: center;
  justify-content: center;
  border-top: 3px solid var(--accent-color2);
  border-bottom: 3px solid var(--accent-color2);
}
.todo-empty__text {
  font-size: 24px;
}

.todo-list {
  position: relative;
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
}
.todo-list__item {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.todo-list__actions {
  display: flex;
  gap: 10px;
  justify-content: right;
}
.todo-list__actions-img {
  cursor: pointer;
  border: 2px solid var(--accent-color);
  padding: 5px;
  width: 36px;
  height: 36px;
  object-fit: none;
  border-radius: 8px;
}
.todo-list__container {
  display: flex;
  border: 2px solid var(--accent-color2);
  border-radius: 8px;
  background-color: var(--bg-color2);
  padding: 15px;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}
.todo-list__article {
  width: clamp(16.25rem, 9.648rem + 28.17vw, 35rem);
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  overflow: hidden;
}
.todo-list__title {
  font-size: 22px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}
.todo-list__text {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}
.todo-list__delete {
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 2px solid var(--accent-color2);
  background-color: var(--bg-color);
}
