@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
:root {
  --bg-color: #1b1a17;
  --bg-color2: #242320;
  --accent-color: #ff8303;
  --accent-color2: #a35709;
  --text-color: #f0e3ca;
}

::selection {
  background-color: var(--accent-color);
  color: var(--bg-color);
}

::-webkit-scrollbar {
  width: 0;
  scrollbar-width: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto Mono", monospace;
  color: var(--text-color);
}

html {
  background-color: var(--bg-color);
}

body {
  width: clamp(18.75rem, 12.148rem + 28.17vw, 37.5rem);
  margin: auto;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  cursor: default;
}

a {
  text-decoration: none;
}

ul,
ol {
  list-style: none;
}
